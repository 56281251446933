﻿@import "variables";
@import "mixins";


 .caption-img .caption{
    opacity: 0;
    margin-top:-55px;
    padding:10px 10px 5px 10px;
    height:55px;
    -webkit-transition:all 0.5s ease;
    -moz-transition:all 0.5s ease;
    -o-transition:all 0.5s ease;
    -ms-transition:all 0.5s ease;
    transition:all 0.5s ease;
    background-color:rgba(0,0,0,0.75);
    color:white;
    position:relative;
    width:100%;
  }

    .caption-img:hover .caption{
    opacity: 1;

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
﻿@import "variables";
@import "mixins";

.siteSearchResults {
    
    background-color: #ffffff;

    tr:hover {
        background-color: #f5f5f5;
    }

    td {
        padding: 1em;

        &.image {
            text-align: center;
            width: 60px;
        }

        a, a:hover, a:visited {
            text-decoration: none;
        }
    }
}

#searchModal .modal-content {
    background-color: transparent;
}

#gsc-iw-id1 {
    height: 100%;
}

.cse .gsc-search-button input.gsc-search-button-v2, input.gsc-search-button-v2 {
    width: initial;
    height: initial;
}

.btnSearch {
    margin-right: 0;
}

.autocomplete-suggestions-header {
    background-color: #e9e9ea;
    text-transform: uppercase;
    font-weight: 400;
    color: #141414;
    border-bottom: 1px double grey;

    small {
        font-weight: normal;
        display: block;
    }

    &:hover {
        background-color: #e9e9ea;
    }
}
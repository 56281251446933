﻿@import "variables";
@import "mixins";

.square-blk {
    //padding:50px 0;
    //clear:both;
    //margin-top:20px;
}

.triangle-topleft {
    width: 0;
    height: 0;
    border-top: 50px solid;
    border-right: 50px solid transparent;
}

.top-border {
    border-top-width: 20px;
    border-top-style: solid;

    &.color1 {
        border-top-color: $color1;
    }

    &.color2 {
        border-top-color: $color2;
    }

    &.color3 {
        border-top-color: $color3;
    }

    &.color4 {
        border-top-color: $color4;
    }

    &.color5 {
        border-top-color: $color5;
    }

    &.color6 {
        border-top-color: $color6;
    }

    &.color7 {
        border-top-color: $color7;
    }

    &.color8 {
        border-top-color: $color8;
    }
}

.content-blk {
    background-color: $color7;
    color: #000;
    min-height: 350px;
    padding-top: 50px;

    &:hover a {
        color: white;
    }
}

.subcontent-blk {
    cursor: pointer;
    color: #000;
    min-height: 140px;
    padding-top: 25px;
}

.content-blk .icon {
    width: 80px;
    height: 75px;
    margin: 0 auto;
    margin-top: -25px;
    transition: all 0.5s ease-in-out;

    a {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.componentBlocks .content-blk {
    min-height: 200px !important;
    padding-top: initial;
}

.top-border {
    padding-top: 50px;
}

.subcontent-blk .icon, .solution .icon {
    //width:50px;
    //height:45px;
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 10px;
    background-size: 98% !important;
    transition: all 0.5s ease-in-out;
}

.icon1, .icon2, .icon3, .icon4, .icon5, .icon6, .iconBoston {
    width: 80px;
    height: 75px;
    float: left;
}

.content-blk-1 .icon, .icon1 {
    @include backgroundimage($file: 'icon-workstation.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1-1 .icon, .icon1 {
    @include backgroundimage($file: 'icon-gaming.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1-2 .icon, .icon1 {
    @include backgroundimage($file: 'icon-office.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1-3 .icon, .icon1 {
    @include backgroundimage($file: 'icon-video.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1-4 .icon, .icon1 {
    @include backgroundimage($file: 'icon-rendering.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1-5 .icon, .icon1 {
    @include backgroundimage($file: 'icon-workstation.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-1-20 .icon, .icon1 {
    @include backgroundimage($file: 'icon-venom.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1-21 .icon, .icon1 {
    @include backgroundimage($file: 'icon-fanless.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2 .icon, .icon2 {
    @include backgroundimage($file: 'icon-servers.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2-1 .icon, .icon2-1 {
    @include backgroundimage($file: 'icon-rackmount.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2-2 .icon, .icon2-2 {
    @include backgroundimage($file: 'icon-serverstorage.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2-3 .icon, .icon2-3 {
    @include backgroundimage($file: 'icon-density.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2-4 .icon, .icon2-4 {
    @include backgroundimage($file: 'icon-tower.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2-5 .icon, .icon2-5 {
    @include backgroundimage($file: 'icon-gpu.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-2-6 .icon, .icon2-6 {
    @include backgroundimage($file: 'icon-servers.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-2-7 .icon, .icon2-7 {
    @include backgroundimage($file: 'icon-server-ipu.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-2-8 .icon, .icon2-8 {
    @include backgroundimage($file: 'icon-mega-dc.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-2-9 .icon, .icon2-9 {
    @include backgroundimage($file: 'icon-OCP.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-2-10 .icon, .icon2-10 {
    @include backgroundimage($file: 'icon-a-plus.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3 .icon, .icon3 {
    @include backgroundimage($file: 'icon-solutions.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1 .icon {
    @include backgroundimage($file: 'icon-cloud.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-2 .icon {
    @include backgroundimage($file: 'icon-converged.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-3 .icon {
    @include backgroundimage($file: 'icon-solutions.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-4 .icon {
    @include backgroundimage($file: 'icon-hpc.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-5 .icon {
    @include backgroundimage($file: 'icon-enterprise.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-6 .icon {
    @include backgroundimage($file: 'icon-vdi.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-1 .icon {
    @include backgroundimage($file: 'icon-solutions.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-2 .icon {
    @include backgroundimage($file: 'icon-headnode.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-3 .icon {
    @include backgroundimage($file: 'icon-hpc.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-4 .icon {
    @include backgroundimage($file: 'icon-hpcstorage.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-5 .icon {
    @include backgroundimage($file: 'icon-hpcethernet.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-6 .icon {
    @include backgroundimage($file: 'icon-hpcservices.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-7 .icon {
    @include backgroundimage($file: 'icon-hpcatlantis.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-8 .icon {
    @include backgroundimage($file: 'icon-hpcvscaler.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-9 .icon {
    @include backgroundimage($file: 'icon-cloudopenstack.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-10 .icon {
    @include backgroundimage($file: 'icon-cloudceph.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-11 .icon {
    @include backgroundimage($file: 'icon-bdhadoop.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-12 .icon {
    @include backgroundimage($file: 'icon-bdsap.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-13 .icon {
    @include backgroundimage($file: 'icon-enterprisevmware.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-14 .icon {
    @include backgroundimage($file: 'icon-enterprisevmware.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-15 .icon {
    @include backgroundimage($file: 'icon-enterprisenexenta.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-16 .icon {
    @include backgroundimage($file: 'icon-enterpriseopene.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-17 .icon {
    @include backgroundimage($file: 'icon-enterprisecitrix.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-18 .icon {
    @include backgroundimage($file: 'icon-enterpriseredhat.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-3-1-19 .icon {
    @include backgroundimage($file: 'icon-immersed.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}


.content-blk-3-1-20 .icon {
    @include backgroundimage($file: 'icon-solutions-5g.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-21 .icon {
    @include backgroundimage($file: 'icon-solutions-immersion.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-22 .icon {
    @include backgroundimage($file: 'icon-solutions-cfd.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-23 .icon {
    @include backgroundimage($file: 'icon-solutions-AI.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-24 .icon {
    @include backgroundimage($file: 'icon-solutions-iot.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-25 .icon {
    @include backgroundimage($file: 'icons-solutions-remote-work.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-26 .icon {
    @include backgroundimage($file: 'icon-solutions-sap.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-27 .icon {
    @include backgroundimage($file: 'icon-solutions-transcoding.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-28 .icon {
    @include backgroundimage($file: 'icon-solutions-vr.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-3-1-29 .icon {
    @include backgroundimage($file: 'icons-solutions-cloud.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4 .icon, .icon4 {
    @include backgroundimage($file: 'icon-storage.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4-1 .icon, .icon4 {
    @include backgroundimage($file: 'icon-nas.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4-2 .icon, .icon4 {
    @include backgroundimage($file: 'icon-storage.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4-3 .icon, .icon4 {
    @include backgroundimage($file: 'icon-storagehpc.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4-4 .icon, .icon4 {
    @include backgroundimage($file: 'icon-hyperconverged.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4-5 .icon, .icon4 {
    @include backgroundimage($file: 'icon-jbod.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-4-6 .icon, .icon4 {
    @include backgroundimage($file: 'icon-storageall.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-5 .icon, .icon5 {
    @include backgroundimage($file: 'icon-networking.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-5-1 .icon, .icon5 {
    @include backgroundimage($file: 'icon-ethernet.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-5-2 .icon, .icon5 {
    @include backgroundimage($file: 'icon-network.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-5-3 .icon, .icon5 {
    @include backgroundimage($file: 'icon-cables.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6 .icon, .icon6 {
    @include backgroundimage($file: 'icon-components.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6-1 .icon, .icon6 {
    @include backgroundimage($file: 'icon-accessories.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6-2 .icon, .icon6 {
    @include backgroundimage($file: 'icon-chassis.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6-3 .icon, .icon6 {
    @include backgroundimage($file: 'icon-cooling.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6-4 .icon, .icon6 {
    @include backgroundimage($file: 'icon-harddrives.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6-5 .icon, .icon6 {
    @include backgroundimage($file: 'icon-graphics.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-6-6 .icon, .icon6 {
    @include backgroundimage($file: 'icon-harddrives.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-7 .icon, .icon6 {
    @include backgroundimage($file: 'icon-management.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-8 .icon, .icon6 {
    @include backgroundimage($file: 'icon-memory.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-9 .icon, .icon6 {
    @include backgroundimage($file: 'icon-mice.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-10 .icon, .icon6 {
    @include backgroundimage($file: 'icon-motherboards.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-11 .icon, .icon6 {
    @include backgroundimage($file: 'icon-compnetwork.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-12 .icon, .icon6 {
    @include backgroundimage($file: 'icon-drives.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-13 .icon, .icon6 {
    @include backgroundimage($file: 'icon-power.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-14 .icon, .icon6 {
    @include backgroundimage($file: 'icon-gpu2.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-15 .icon, .icon6 {
    @include backgroundimage($file: 'icon-cabinets.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-16 .icon, .icon6 {
    @include backgroundimage($file: 'icon-ssd.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-17 .icon, .icon6 {
    @include backgroundimage($file: 'icon-compstorage.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-18 .icon, .icon6 {
    @include backgroundimage($file: 'icon-compvideo.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk-6-19 .icon, .icon6 {
    @include backgroundimage($file: 'icon-warranty.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk .icon-venom, .icon-venom {
    @include backgroundimage($file: 'icon-venom.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}
.content-blk .icon-fanless, .icon-fanless {
    @include backgroundimage($file: 'icon-fanless.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.iconBoston {
    @include backgroundimage($file: 'icon-boston.png', $path: "/assets/images/2016/icons/", $repeat: no-repeat,$position: center top);
}

.content-blk-1:hover .icon,
.content-blk-2:hover .icon,
.content-blk-3:hover .icon,
.content-blk-4:hover .icon,
.content-blk-5:hover .icon,
.content-blk-6:hover .icon,
.content-blk:hover .icon,
.iconactive {
    background-position-y: -75px;
}

.subcontent-blk:hover .icon {
    //background-position-y:-45px;
    background-position-y: -70px;
}

.product-specs.active {
    background-color: #7e7e7f;
    color: white;
    position: relative;
    z-index: 5;

    li, .color3 {
        color: white;
    }

    .btn-red, .btn-yellow, .btn-grey {
        color: #fff;
        background-color: transparent;
        border-color: #fff;
        border: 1px solid #fff;

        &:hover {
            background-color: #ea4624;
            color: #fff;
            border-color: #ea4624;
        }
    }
}

﻿@import "variables";
@import "mixins";

.video-blk {
  min-height: 260px;
  //margin-top:100vh;
  
}

#video-container {
  position: absolute;
 // padding:20px 0;
}
#video-container {
  //min-height:260px;
  width:100%;
  overflow: hidden;
}
video {
  position:absolute;
  z-index:0;
  //padding:20px 0;
  //margin-top:-220px;
}
video.fillWidth {
  width: 100%;
}

.carousel video {
  height:100vh;
  min-height:650px;
  width:100%;
  object-fit:cover;
}

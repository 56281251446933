﻿@import "variables";
@import "mixins";

.navbar-collapse {
    max-height: none !important;
    position: relative;
}

.navbar-right {
    margin-right: 0 !important;
}

.navbar-brand {
    height: 80px;
}

.navbar-collapse .navoption {
    border-left: 1px solid #e9e9ea;
    padding: 20px;
    min-height: 220px;
}

.navbar-collapse .navoption .dropdown .col-md-10 {
    min-height: 200px;
}

.navbar-default .dropdown-menu a.btn:not(.sink) {
    position: absolute;
    bottom: 0;
}

.navbar-collapse .navoption:last-of-type {
    border-right: 1px solid #e9e9ea;
}

div.prepop {
    display: none;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    position: relative;
    z-index: 99;
    margin-top: -10px;
}

ul.nav {
    margin-top: 10px;
    //text-transform:uppercase;
}

.navbar-default .navbar-nav > li > a {
    text-transform: uppercase;
}

.navbar {
    border: 0;
    background-color: #141414;
    /* Fallback for web browsers that doesn't support RGBa */
    /*background: rgb(0, 0, 0);*/
    /* RGBa with 0.6 opacity */
    /*background: rgba(0, 0, 0, 0.8);*/
}

.dropdown-header {
    font-size: 1.2em;
    text-transform: uppercase;
    padding-left: 0;
}

.dropdown-menu {
    font-size: 0.9em;
    border: 0;
}

.dropdown-menu ul {
    list-style-type: none;
    //text-transform:uppercase;
    margin-bottom: 20px;
    padding: 0;
}

.dropdown-menu .col-header {
    font-weight: bold;
}

.navbar-default .navbar-toggle {
    color: #fff;
    border: 0;
}

.navbar-toggle {
    margin-right: 0;
}

.breadcrumb {
    background-color: transparent;
    padding: 8px 0;
}

.breadcrumb > .active {
    color: inherit;
}

.navbaricons {
    //margin-right:20px;
    width: 60vw;
    text-align: right;
}

.navbaricons a {
    margin-left: 20px;
}
/********** Navigation **********/
.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > .open > a {
    color: $color-white;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .open > a:hover {
    color: $color-white !important;
    background-color: $color6 !important;
    text-decoration: underline;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .open > a:focus {
    color: $color-white !important;
    background-color: $color6 !important;
}

.navbar-default .dropdown-menu {
    background-color: $color8 !important;
    color: $color-white !important;
}

.navbar-default .dropdown-menu li {
  background-color: #141414 !important;
  color: #ffffff !important;
  position: relative;
  z-index: 9999;
}

.navbar-default .dropdown-menu a {
    color: $color-white !important;
}

.navbar-default .dropdown-menu a:hover {
    background-color: transparent;
    color: $color-white !important;
}

.navbar-default .dropdown-header {
    color: $color-white;
}

.adminBar {
    background: rgba(0, 173, 216, 0.8);
    color: white;

    a, a:hover {
        color: white;
    }

    a:hover {
        text-decoration: underline;
    }
}

﻿@import "variables";
@import "mixins";

.product-logo {
    z-index: 999;
    position: relative;
    margin-top: -65px;
    float: right;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    border: 1px solid $color7;
    line-height: 72px;
    vertical-align: middle;
}

.product-logo img {
    max-width: 80px;
    margin: 0 auto;
    margin-top: 20px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.product-logo2 {
    z-index: 999;
    position: relative;
    float: right;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
}

.product-logo2 img {
    max-width: 80px;
    margin: 0 auto;
    margin-top: 20px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.filterbar {
    margin-top: -120px;
}

.filters .dropdown, .filters .removable-button {
    padding-left: 0;
    margin-bottom: 10px;
}

.filters .dropdown .btn, .filters .removable-button .btn {
    width: 100%;
    text-align: left;
}

.filters .removable-button .btn .pull-right {
    margin-top: -0.5em;
}

.filters .removable-button .btn:hover {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.filters hr {
    margin-top: 10px;
    margin-bottom: 20px;
    border-top-color: #ddd;
}

.filters .dropdown .dropdown-menu {
    //position:relative;
    margin: 0;
}

/*.filterproduct {
    height: 220px;
    margin: 30px 0;
    position: relative;
    z-index: 999;
}

.filterproduct .title {
    margin: 20px 0;
}

.filterproduct img {
    margin: 0 auto;
    text-align: center;
    float: none;
    max-width: 100%;
}*/

.filters .price-slider {
    background-color: $color-white;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px 0;
    border: 1px solid #ccc;
    font-weight: 400;
}

.filters input {
    border: 0;
    padding: 0;
    background: transparent;
}

.filters .price-slider label {
    font-weight: 400;
    padding-top: 2px;
    margin: 0;
}

ul.product-specs {
    padding-left: 0;
}

.product-specs li {
    padding: 10px 20px;
    margin: 0 20px;
    list-style-type: none;
    border-top: 1px solid $color7;
}

.flagship-product {
    max-height: 500px;
    margin: 15px auto;
}

/********** Pricebar **********/
.pricebar, .comparisonbar {
    z-index: 9999999;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: transparent;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(0, 0, 0);
    /* RGBa with 0.6 opacity */
    background: rgba(0, 0, 0, 0.75);
}

.productfilter .pricebar {
    display: none;
}

#comparisonBar {
    .comparisonBarClose {
        position: absolute;
        right: 0;
    }

    #comparelist {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
            float: left;
            padding: 0;
            margin: 0;

            img {
                margin: 0 10px;
                border: 2px solid;
            }
        }
    }

    .title {
        padding: 10px 0;
    }
}

#productComparisonModal {
    .modal-dialog {
        width: 75%;
        margin-top: 120px;
        padding-bottom: 120px;
    }

    .modal-content {
        /*padding: 0 !important;*/
    }

    #specs {
        .search {
            float: left;
            text-transform: uppercase;
            box-shadow: none;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }

        .list {
            list-style: none;
            padding: 0;
            margin: 0;

            > li {
                width: 100%;
                display: inline-block;

                .spec, .value {
                    width: 25% !important;
                    float: left;
                }

                p.value > span {
                    display: block;
                }
            }
        }
    }
}

.hq-product img {
    overflow: hidden;
    margin-bottom: -10%;
}

.product-overview-form {
    .form-group {
        margin-bottom: 0;
    }
}

#flagfeat {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}





/*New catalog page*/


.productbg {
    background-color: #fbfbfc;
}



.on-state {
    display: none;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 9999;
    background-color: #fff;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(255, 255, 255);
    /* RGBa with 0.6 opacity */
    background: rgba(255, 255, 255, 0.80);
    height: 360px;
    //margin:30px 0;
    padding-top: 60px;
    margin-top: -310px;

    .btn {
        border-radius: 0px;
        width: 100%;
        font-size: 0.75em;
        padding: 15px 0;
        margin: 2px 0;
    }

    .oos {
        text-align: center;
        font-size: 30px;
        vertical-align: middle;
        text-transform: uppercase;
    }
}

.top {
    //position:relative;
    //z-index:99;
    position: absolute !important;
    top: 0px;
}

.full-parent-height {
    height: 100% !important;
}

.lge {
    font-size: 2em;
}

.halfway {
    margin-top: 20vh;
    max-height: 80vh;
}

.halfway-parent {
    margin-top: 25% !important;
}

.filterproduct {
    height: auto;
    display: block;
    margin: 10px 0px;
    position: relative;
    min-height: 175px;
    transition: all 0.5s ease-in-out;
    background-color: white;
    border: 1px solid #e1e1e1;
    position: relative;
    z-index: 999;

    .image_area {
        padding-top: 50px;
    }

    .mainkeyfeat {
        background-color: #7e7e7f;
        padding: 10px;
        color: white;
        font-weight: bold;
        margin-left: -15px;
        margin-top: -50px;
        width: auto;
        float: left;
        text-align: center;
        position: relative;
        z-index: 99999;
    }

    .mainfeat {
        height: auto;
        float: left;
    }

    .keyfeat {
        border-left: 1px solid #e9e9ea;
        min-height: 50px;
        font-size: 1em;
        color: #7e7e7f;
        margin-top: 20px;
        height: auto;

        > div {
            padding-left: 15px;
        }
    }

    .partnum {
        font-size: .75em;
        color: #7e7e7f;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .badges {
        margin-top: 10px;

        img {
            margin: 0 10px 10px 0;
        }
    }

    .title {
        margin: 20px 0;
        padding-right: 30px;
    }

    img.productshot {
        text-align: center;
        max-width: 300px;
        max-height: 300px;
        margin: 0 auto;
        float: none;
    }

    .on-state {
        display: none;
        color: white;
        height: auto;
        padding: 0px;
        margin-top: 0px;
        background-color: #00add8;
        /* Fallback for web browsers that doesn't support RGBa */
        background: rgb(0, 173, 216);
        /* RGBa with 0.6 opacity */
        background: rgba(0, 173, 216, 0.90);
    }
}

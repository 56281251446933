﻿@import "../variables";
@import "../mixins";

// Latest Blog scss code

.blog-blk {
    @include backgroundimage("bostonwallpaper2.jpg", $position: 0 90%, $attachment: fixed);
    background-size: cover;
    padding: 100px 0;

    .content-blk {
        background-color: white;
    }
}

.pagination > li > a {
    color: $color1;
}

ul.pagination {
    padding: 20px 0 40px 0;
}

.new-blog-blk {
    background-image: url('https://bstncdn.net/i/6918');
    background-position: 0 90%;
    background-attachment: fixed;
    background-size: cover;
    padding: 100px 0;

    .content-blk {
        background-color: white;
    }
}

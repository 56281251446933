@import "variables";
@import "mixins";


.list-unstyled {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
}

.ml15 {
	margin-left: 15px;
}

.dark {
	color: $color8;
}

.bold {
	font-weight: bold;
}

.upper {
	text-transform: uppercase;
}

.lower {
	text-transform: lowercase;
}

.btn {
	text-transform: uppercase;
}

.center {
	margin: 0 auto;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.bottom {
	position: relative;
	bottom: 0;
}

.fr {
	float: right;
}

.fl {
	float: left;
}

.pr10 {
	padding-left: 10px;
}

.pl0 {
	padding-left: 0;
}

.rounded {
	@include rounded(25px);
	border: 1px solid;
	padding: 10px;
	width: 45px;
	height: 45px;
	text-align: center;
	vertical-align: middle;
}

.arrow-link {
	padding: 0;
	font-size: 1.25em;
	line-height: 40px;
}

.fa, .fa-facebook, .fa-twitter, .fa-linkedin, .fa-youtube {
	margin-right: 10px;
}

.pager li > a {
	border-radius: 5px;
	text-transform: uppercase;
}

.vert-align {
	position: relative;
	bottom: 50%;
	transform: translateY(50%);
}

.padding-5{
    padding: 5px;
}
.padding-10{
    padding: 10px;
}

.padded-none {
	padding: 0;
}

.padded-top {
	padding-top: 15px;
}

.padded-sides {
	padding: 0 15px;
}

.padded-bottom {
	padding-bottom: 15px;
}

.padded {
	padding: 15px;
}

.margined {
	margin: 15px;
}

.margined-top {
	margin: 15px 0;
}

.border-left {
	border-left: 1px solid;
	border-color: #e9e9ea;
}

.border-right {
	border-right: 1px solid;
	border-color: #e9e9ea;
}

.border-top {
	border-top: 1px solid;
	border-color: #e9e9ea;
}

.border-bottom {
	border-bottom: 1px solid;
	border-color: #e9e9ea;
	padding-bottom: 50px;
}

.circle {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid #e9e9ea;
	height: 50px;
	width: 50px;
	background: rgba(255,255,255,0.5);
	padding: 10px 0 0 10px;
}

.top-margined {
	margin: 20px 0;
}

.gutterrow {
	padding: 30px 0;
}

.full-width, .full-width img {
  width:100% !important;
}
.half-width {
  width:50% !important;
}

.third-width {
  width:30% !important;
}

.quarter-width {
  width:25% !important;
}

.bandw {
   -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.subtitle {
	text-transform: uppercase;
	/* font-weight: bold; */
	font-size: 1.25em;
	margin: 30px 0;
}

.subtitle:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f054\0020';
    color: #00add8;
}

.full-width {
	width: 100% !important;
}

.lge {
	font-size: 2em;
}

.xlge {
	font-size: 10em;
}

.med {
	font-size: 1.5em;
}

.sml {
	font-size: .75em;
}

.relative {
	position: relative;
}

.moveup {
	margin-top: -40px;
}

.moveupsml {
	margin-top: -10px;
}

.moveback {
	position: relative;
	z-index: 0;
}

.moveforward {
	position: relative;
	z-index: 10;
}
/********** Colours **********/
.color1 {
	color: $color1 !important;
}

.color2 {
	color: $color2;
}

.color3 {
	color: $color3;
}

.color4 {
	color: $color4;
}

.color5 {
	color: $color5;
}

.color6 {
	color: $color6;
}

.color7 {
	color: $color7;
}

.light {
	color: $color-white;
}

.lightbg {
	background-color: $color-white;
}

.mid,
a.mid {
	color: $color7;
}

.midbg {
	background-color: $color7;
}

.dark {
	color: $color8;
}

.darkbg {
	background-color: $color8;
}

.color1bg {
	background-color: $color1;
}

.color2bg {
	background-color: $color2;
}

.color3bg {
	background-color: $color3;
}

.color4bg {
	background-color: $color4;
}

.color5bg {
	background-color: $color5;
}

.color6bg {
	background-color: $color6;
}

.color7bg {
	background-color: $color7;
}

.bordercolor1 {
	color: $color1;
}

.bordercolor2 {
	color: $color2;
}

.bordercolor3 {
	color: $color3;
}

.bordercolor4 {
	color: $color4;
}

.bordercolor5 {
	color: $color5;
}

.bordercolor6 {
	color: $color6;
}

.bordercolor7 {
	color: $color7;
}

.gradientbg {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e9e9ea+100&amp;0+29,1+70,1+100 */
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0) 29%, #f0f0f0 70%, $color7 100%);
	/* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(29%, rgba(249, 249, 249, 0)), color-stop(70%, #f0f0f0), color-stop(100%, $color7));
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0) 29%, #f0f0f0 70%, $color7 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0) 29%, #f0f0f0 70%, $color7 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0) 29%, #f0f0f0 70%, $color7 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(249, 249, 249, 0) 29%, #f0f0f0 70%, $color7 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='$color7', GradientType=0);
	/* IE6-9 */
}

.default_ptn_bg {
	@include backgroundimage($file: "blue-background.jpg", $path: $image_base, $repeat: no-repeat, $position: center);
	background-size: cover;
}

a,
a:hover {
	color: $color1;
}

.light a {
	color: $color-white;
}

.light a:hover {
	color: $color-white;
}

a.dark {
	color: $color8;
}

a.dark:hover {
	color: $color8;
}
/********** Square blocks **********/
.content-blk-1,
.content-blk-2,
.content-blk-3,
.content-blk-4,
.content-blk-5,
.content-blk-6,
.content-blk,
.subcontent-blk {
	transition: all 0.5s ease-in-out;
}

div[class^="content-blk-1-"]:hover,
div[class*=" content-blk-1-"]:hover,
.content-blk-1:hover {
	background-color: $color1;
	color: $color-white;
}

div[class^="content-blk-2-"]:hover,
div[class*=" content-blk-2-"]:hover,
.content-blk-2:hover {
	background-color: $color2;
	color: $color-white;
}

div[class^="content-blk-3-"]:hover,
div[class*=" content-blk-3-"]:hover,
.content-blk-3:hover {
	background-color: $color3;
	color: $color-white;
}

div[class^="content-blk-4-"]:hover,
div[class*=" content-blk-4-"]:hover,
.content-blk-4:hover {
	background-color: $color4;
	color: $color-white;
}

div[class^="content-blk-5-"]:hover,
div[class*=" content-blk-5-"]:hover,
.content-blk-5:hover {
	background-color: $color5;
	color: $color-white;
}

div[class^="content-blk-6-"]:hover,
div[class*=" content-blk-6-"]:hover,
.content-blk-6:hover {
	background-color: $color6;
	color: $color-white;
}

div[class^="content-blk-7-"]:hover,
div[class*=" content-blk-7-"]:hover,
.content-blk-7:hover {
    background-color: $color7;
    color: $color-white;
}

div[class^="content-blk-8-"]:hover,
div[class*=" content-blk-8-"]:hover,
.content-blk-8:hover {
    background-color: $color8;
    color: $color-white;
}

div[class^="subcontent-blk"]:hover,
div[class*=" subcontent-blk"]:hover,
.subcontent-blk:hover {
	background-color: $color3;
	color: $color-white;
}

.content-blk a,
.subcontent-blk a {
	text-decoration: none;
	color: $color8;
}

.content-blk a:hover,
.subcontent-blk a:hover {
	color: $color-white;
}

/********** Hero **********/
.hero-blk1 .image-background {
	@include backgroundimage("hero-workstations.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.hero-blk2 .image-background {
	@include backgroundimage("hero-servers.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.hero-blk3 .image-background {
	@include backgroundimage("hero-solutions.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.hero-blk4 .image-background {
	@include backgroundimage("hero-storage.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.hero-blk5 .image-background {
	@include backgroundimage("hero-networking.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.hero-blk6 .image-background {
	@include backgroundimage("hero-components.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.hero-default .image-background {
	@include backgroundimage("hero-default.png", "/assets/images/2016/icons/", $position: right 0);
	background-color: transparent;
}

.breadcrumb a {
	color: $color6;
}

/********** Filter colours **********/

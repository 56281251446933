﻿@import "variables";
@import "mixins";

.callout-blk {
  padding:50px 20px;
  //font-weight: lighter;
  line-height: 30px;

}

.homepage .nl-sml-blk {
  padding:20px 0;
}

.sectcallout-blk {
    @include backgroundimage("bostonlabs4.jpg", $position: 50% 0, $attachment: fixed );
  background-size:cover;
  background-position: center;
  //height:400px;
  font-size:1.5em;
  //font-weight:lighter;
  padding:120px 0;
}

.sectcallout-blk-meettheteam {
    @include backgroundimage("meettheteam-callout.jpg", $position: 50% 0, $attachment: fixed );
  background-size:cover;
  background-position: center;
  //height:400px;
  font-size:1.5em;
  //font-weight:lighter;
  padding:120px 0;
}

.sectcallout-blk-cloud {
    @include backgroundimage("clouds-solutions.jpg", $position: 50% 0, $attachment: fixed );
  background-size:cover;
  background-position: center;
  //height:400px;
  font-size:1.5em;
  //font-weight:lighter;
  padding:120px 0;
}

.sectcallout-blk-converged {
    @include backgroundimage("converged-solutions.jpg", $position: 50% 0, $attachment: fixed );
  background-size:cover;
  background-position: center;
  //height:400px;
  font-size:1.5em;
  //font-weight:lighter;
  padding:120px 0;
}

.sectcallout-blk-bigdata {
    @include backgroundimage("bigdata-solutions.jpg", $position: 50% 0, $attachment: fixed );
  background-size:cover;
  background-position: center;
  //height:400px;
  font-size:1.5em;
  //font-weight:lighter;
  padding:120px 0;
}

.sectcallout-blk-hpc {
    @include backgroundimage("hpc-solutions.jpg", $position: 50% 0, $attachment: fixed );
  background-size:cover;
  background-position: center;
  //height:400px;
  font-size:1.5em;
  //font-weight:lighter;
  padding:120px 0;
}
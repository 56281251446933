﻿@import "bootstrap-variables";
@import "variables";
@import "mixins";
@import "mixins/buttons";

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: Qanelas-Regular, sans-serif;
    font-weight: normal;
    font-style: normal;
}
.h1{
    margin: 0.67em 0;
}

/********** Jumbotron **********/
.container .jumbotron,
.container-fluid .jumbotron {
    border-radius: 0;
}

.jumbotron, .jumbotron-nosurround {
    margin-top: 120px;
    border-radius: 0;
}


.jumbotron h1 {
    font-size: 3em;
    line-height: 1.5em;
}

.jumbotron-nosurround h1 {
    font-size: 3.5em;
}

.jumbotron-nosurround p {
    font-size: 1.5em;
}

.jumbo-blue {
    background-color: $color1;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(0, 173, 216);
    /* RGBa with 0.6 opacity */
    background: rgba(0, 173, 216, 0.8);
}

.jumbo-white {
    background-color: #fff;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(255, 255, 255);
    /* RGBa with 0.6 opacity */
    background: rgba(255, 255, 255, 0.8);
}

.jumbo-red {
    background-color: $color3;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(234, 70, 36);
    /* RGBa with 0.6 opacity */
    background: rgba(234, 70, 36, 0.8);
}

.jumbo-yellow {
    background-color: $color2;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(254, 187, 25);
    /* RGBa with 0.6 opacity */
    background: rgba(254, 187, 25, 0.8);
}

.jumbo-green {
    background-color: $color5;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(19, 184, 58);
    /* RGBa with 0.6 opacity */
    background: rgba(19, 184, 58, 0.8);
}

.jumbo-black {
    background-color: #000000;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgb(0, 0, 0);
    /* RGBa with 0.6 opacity */
    background: rgba(0, 0, 0, 0.6);
}

.jumbotron p {
    font-size: 18px;
}

@media (min-width: 768px) {

    .form-horizontal .control-label.text-left {
        text-align: left;
    }

}

[class^="icon-"],
[class*=" icon-"] {

  background-image: url("/assets/css/bootstrap/img/glyphicons-halflings.png");
}

/* White icons with optional class, or on hover/focus/active states of certain elements */

.icon-white,
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:focus > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > li > a:focus > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"],
.dropdown-submenu:hover > a > [class^="icon-"],
.dropdown-submenu:focus > a > [class^="icon-"],
.dropdown-submenu:hover > a > [class*=" icon-"],
.dropdown-submenu:focus > a > [class*=" icon-"] {
  background-image: url("/assets/css/bootstrap/img/glyphicons-halflings-white.png");
}

@font-face {
    font-family: 'Glyphicons Halflings';
    font-display: swap;
    src: url('/assets/fonts/glyphicons-halflings-regular.eot');
    src: url('/assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('/assets/fonts/glyphicons-halflings-regular.woff') format('woff'), url('/assets/fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('/assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}


@media (max-width: 793px){
    .navbar-collapse ul.nav.navbar-nav {
        height: 55vh; overflow: auto
    }
}

btn {
    &.btn-primary, &.btn-success, &.btn-info, &.btn-warning, &.btn-danger {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.btn {

    @extend .btn-sm;

    &.btn-default {
        border-color: #ccc !important;
    }

    &.btn-primary {
        @include button-variant($brand-primary);
    }

    &.btn-secondary {
        @include button-variant(#6c757d);
    }

    &.btn-success {
        @include button-variant($brand-success);
    }

    &.btn-info {
        @include button-variant($brand-info);
    }

    &.btn-warning {
        @include button-variant($brand-warning);
    }

    &.btn-danger {
        @include button-variant($brand-danger);
    }

    &.btn-light {
        @include button-variant(#f8f9fa);
        color: #212529 !important;
    }

    &.btn-dark {
        @include button-variant(#343a40);
    }

    &.btn-outline-default {
        @extend .btn-default;
    }

    &.btn-outline-primary {
        @include button-outline-variant($brand-primary, #fff, $btn-primary-bg, $btn-primary-border);
    }

    &.btn-outline-secondary {
        @include button-outline-variant(#6c757d, #fff, #6c757d, #6c757d);
    }

    &.btn-outline-success {
        @include button-outline-variant($brand-success, #fff, $btn-success-bg, $btn-success-border);
    }

    &.btn-outline-info {
        @include button-outline-variant($brand-info, #fff, $btn-info-bg, $btn-info-border);
    }

    &.btn-outline-warning {
        @include button-outline-variant($brand-warning, #fff, $btn-warning-bg, $btn-warning-border);
    }

    &.btn-outline-danger {
        @include button-outline-variant($brand-danger, #fff, $btn-danger-bg, $btn-danger-border);
    }

    &.btn-outline-light {
        @include button-outline-variant(#f8f9fa, #f8f9fa, #f8f9fa, #f8f9fa);
    }

    &.btn-outline-dark {
        @include button-outline-variant(#343a40, #fff, #343a40, #343a40);
    }
}

.btn.btn-link {
    &.text-muted {
        color: $text-muted;
    }

    &.text-primary {
        color: $brand-primary;
    }

    &.text-success {
        color: $brand-success;
    }

    &.text-info {
        color: $brand-info;
    }

    &.text-warning {
        color: $brand-warning;
    }

    &.text-danger {
        color: $brand-danger;
    }
}

.rounded-corners {
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.rounded-corners-fullwidth {
    width: 100%;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul.new-blue li::marker {
    color: #3b4395 !important;
}

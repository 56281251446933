@import "variables";
@import "mixins";

.dragHandle {
    @include backgroundimage($file: "draghandle.png", $path: "/assets/images/", $repeat: repeat);
    background-color: $color-white;
    width: 6px;
}

table {
    border-color: $color7;
    //margin:50px 0;
    width: 100%;
}

td, th {
    padding: 15px;
}

th {
    background-color: $color6;
    color: $color-white;
    font-weight: normal;
    text-transform: uppercase;
}

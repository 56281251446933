﻿@import "variables";
@import "mixins";

h3.menu {
}

ul.menu {
    width: 100%;
    li:before {
        padding-right: 0.6em;
    }
}

ul.arrow {
    li:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f054\0020';
        color: $color7;
        display: inline;
        height: 100%;
        display: table-cell; /* aha! */
        text-align: right;
    }

    li {
        font-family: 'Open sans';
        //font-weight:300;
        line-height: 1.5em;
        display: table-row;
    }
}

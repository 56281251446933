﻿@import "variables";
@import "mixins";

.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel-indicators {
  bottom:20px;
}

.carousel,
.carousel-inner,
.carousel-inner .item {
    height: 55vh;
    min-height:550px;
    background-color: black;
    background-size: cover !important;
    background-position: center !important;
}

.carousel-scroll {
  display:none;
  position:absolute;
  @include backgroundimage("arrow.png", $position: center center);
  bottom: 0;
  height: 100px;
  text-indent:-999999px;
  position: absolute;
  z-index: 15;
  width: 100%;
  margin: 0 auto;
  padding-bottom:50px;
  margin-bottom:10px;
  //display:inline-block;
  text-align:center;
  text-align: center;
  list-style: none;
  background-repeat: no-repeat;
  background-size:20%;
}


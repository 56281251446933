﻿@import "variables";
@import "mixins";


.tw-widget1 {

    .user {
        padding: 20px 20px 16px;
        position: relative;
        color: white !important;

        a, a span {
            display: block;
            text-align: left;
            color: white;
            font-weight: bold;
        }

        img {
            float: left;
            margin-right: 1em;
        }

        .displayName {
            font-size: 20px;
        }

        .screenName a {
            font-size: 14px;
            color: black;
        }
    }

    .tweet {
        padding: 20px 20px 16px;
        margin: 0;
        color: white;
        min-height: 8em;

        a, a:hover, a:active, a:visited {
            color: white;
            font-weight: bold;
        }
    }

    .timePosted {
        padding: 20px 20px 16px;
        margin: 0;

        &::before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
            content: '\f017';
            padding-right: 4px;
        }
    }

    a {
        text-decoration: none;
    }
}

.fa-twitter:before {
	content: '\f099';
}

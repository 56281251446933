@import "variables";
@import "mixins";

/**
 * @license
 * MyFonts Webfont Build ID 3623075, 2018-08-16T05:36:49-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Qanelas-ExtraBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/qanelas/extra-bold/
 * 
 * Webfont: Qanelas-ExtraBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/qanelas/extra-bold-italic/
 * 
 * Webfont: Qanelas-LightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/qanelas/light-italic/
 * 
 * Webfont: Qanelas-Light by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/qanelas/light/
 * 
 * Webfont: Qanelas-RegularItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/qanelas/regular-italic/
 * 
 * Webfont: Qanelas-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/qanelas/regular/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3623075
 * Licensed pageviews: 100,000
 * Webfonts copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Copyright 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
//@import url("//hello.myfonts.net/count/3748a3");

@font-face {
    font-family: 'Qanelas-ExtraBold';
    font-display: swap;
    src: url('/assets/fonts/qanelas/3748A3_0_0.eot');
    src: url('/assets/fonts/qanelas/3748A3_0_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/qanelas/3748A3_0_0.woff2') format('woff2'),url('/assets/fonts/qanelas/3748A3_0_0.woff') format('woff'),url('/assets/fonts/qanelas/3748A3_0_0.ttf') format('truetype'),url('/assets/fonts/qanelas/3748A3_0_0.svg#wf') format('svg');
}


@font-face {
    font-family: 'Qanelas-ExtraBoldItalic';
    font-display: swap;
    src: url('/assets/fonts/qanelas/3748A3_1_0.eot');
    src: url('/assets/fonts/qanelas/3748A3_1_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/qanelas/3748A3_1_0.woff2') format('woff2'),url('/assets/fonts/qanelas/3748A3_1_0.woff') format('woff'),url('/assets/fonts/qanelas/3748A3_1_0.ttf') format('truetype'),url('/assets/fonts/qanelas/3748A3_1_0.svg#wf') format('svg');
}


@font-face {
    font-family: 'Qanelas-LightItalic';
    font-display: swap;
    src: url('/assets/fonts/qanelas/3748A3_2_0.eot');
    src: url('/assets/fonts/qanelas/3748A3_2_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/qanelas/3748A3_2_0.woff2') format('woff2'),url('/assets/fonts/qanelas/3748A3_2_0.woff') format('woff'),url('/assets/fonts/qanelas/3748A3_2_0.ttf') format('truetype'),url('/assets/fonts/qanelas/3748A3_2_0.svg#wf') format('svg');
}


@font-face {
    font-family: 'Qanelas-Light';
    font-display: swap;
    src: url('/assets/fonts/qanelas/3748A3_3_0.eot');
    src: url('/assets/fonts/qanelas/3748A3_3_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/qanelas/3748A3_3_0.woff2') format('woff2'),url('/assets/fonts/qanelas/3748A3_3_0.woff') format('woff'),url('/assets/fonts/qanelas/3748A3_3_0.ttf') format('truetype'),url('/assets/fonts/qanelas/3748A3_3_0.svg#wf') format('svg');
}


@font-face {
    font-family: 'Qanelas-RegularItalic';
    font-display: swap;
    src: url('/assets/fonts/qanelas/3748A3_4_0.eot');
    src: url('/assets/fonts/qanelas/3748A3_4_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/qanelas/3748A3_4_0.woff2') format('woff2'),url('/assets/fonts/qanelas/3748A3_4_0.woff') format('woff'),url('/assets/fonts/qanelas/3748A3_4_0.ttf') format('truetype'),url('/assets/fonts/qanelas/3748A3_4_0.svg#wf') format('svg');
}


@font-face {
    font-family: 'Qanelas-Regular';
    font-display: swap;
    src: url('/assets/fonts/qanelas/3748A3_5_0.eot');
    src: url('/assets/fonts/qanelas/3748A3_5_0.eot?#iefix') format('embedded-opentype'),url('/assets/fonts/qanelas/3748A3_5_0.woff2') format('woff2'),url('/assets/fonts/qanelas/3748A3_5_0.woff') format('woff'),url('/assets/fonts/qanelas/3748A3_5_0.ttf') format('truetype'),url('/assets/fonts/qanelas/3748A3_5_0.svg#wf') format('svg');
}


.Qanelas-ExtraBold {
    font-family: Qanelas-ExtraBold;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

.Qanelas-ExtraBoldItalic {
    font-family: Qanelas-ExtraBoldItalic;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

.Qanelas-LightItalic {
    font-family: Qanelas-LightItalic;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

.Qanelas-Light {
    font-family: Qanelas-Light;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

.Qanelas-RegularItalic {
    font-family: Qanelas-RegularItalic;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

.Qanelas-Regular {
    font-family: Qanelas-Regular;
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

﻿/*
 Cloud Zoom CSS. (c)2012-2015 Star Plugins.
*/

/* CSS for lens */
.cloudzoom-lens {
    border:5px solid #888;
    width:100px;
    height:100px;
    box-shadow: -0px -0px 10px rgba(0,0,0,0.40);
    cursor:crosshair;
    z-index: 9999;
}
/* CSS for zoom window. */
.cloudzoom-zoom {
    border:1px solid #888;
    width:600px;
    height:200px;
    box-shadow: -0px -0px 10px rgba(0,0,0,0.40);
	z-index: 9999;
}
/* CSS for zoom window in 'inside' mode. */
.cloudzoom-zoom-inside {
    border:none;
    box-shadow:none;
	z-index: 9999;
}

/* CSS for captions */
.cloudzoom-caption {
    display:none;   /* CSS captions should be hidden initially */
    text-align: left;
    background-color:#000;
    color:#fff;
    font-weight:bold;
    padding:10px;
    font-family: sans-serif;
    font-size:11px;
   
}

/* A blank image */
.cloudzoom-blank {
    background-image:url(blank.png);
}

/* The animated ajax loading image */
.cloudzoom-ajax-loader {
    background-image:url(ajax-loader.gif);
    width:32px;
    height:32px;
}


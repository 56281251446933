@import "variables";
@import "mixins";

fieldset {
    clear: both;
    border: 0;

    ol {
        list-style: none;

        li {
            label {
                display: block;
            }
        }
    }
}

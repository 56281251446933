﻿@import "variables";
@import "mixins";

@media (max-width: 1200px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
  		display:block !important;
	}

	.productshot {
		margin-top:10px;
	}
}

@media (max-width: 768px) {
	.video-blk {
	  min-height: 300px;	  
	}

	.nav {
		position:relative;
	}

	.navbar {
	    /* RGBa with 0.6 opacity */
	  background: rgba(0, 0, 0, 1);
	}

	.nav>li>a {
  		padding: 10px 5px;
  		//font-size: x-small;
	}

	.tab-content .search {
 		 width: 100%;
	}

	.date-col {
		padding:0 !important;
	}

	.year-mnth {
		font-size:0.75em;
		line-height:1.2em;
	}

	.jumbotron h1, .jumbotron-nosurround h1 {
	  font-size:2em !important;
	}

	.jumbotron-nosurround p {
	  font-size:1em;
	}

	.jumbotron, .jumbotron-nosurround {
	  margin-top:35% !important;
	  border-radius:0;
	}

	img.productshot {
		/*margin:0 auto;*/
		//margin-top:-30px;
		/*width:70%;*/
		/*margin-bottom:20px;
		padding-bottom:80px;*/
	}

	.spec, .value {
		width:100%;
	}

	.border-left {
		border:0;
	}

	.video-holder iframe {
		max-width:360px;
	}

	.tabs .nav li a {
		color:black;
	}
}

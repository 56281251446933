﻿@import "variables";
@import "mixins";

.tab-content {
	background-color: white;
	//font-weight:300;
}

ul.list {
	padding-left: 0;
	list-style: none;
	margin-top: 20px;
}

#specs ul.list li {
	width: 100%;
	clear: both;
	padding: 20px 0;
	display: inline-block;
	border-top: 1px solid;
	border-color: #f1f1f1;
}

.spec, .value {
	float: left;
}

.spec {
	width: 35%;
	text-transform: uppercase;
	font-size: 20px;
}

.value {
	width: 65%;
	font-size: 14px;
}

label.spec-search {
	font-size: 1.2em;
	font-weight: 100;
	line-height: 30px;
}

.tab-content .search {
	width: 24%;
	float: left;
	margin-right: 10px;
	text-transform: uppercase;
	box-shadow: none;
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

.product .moveup .nav-tabs {
	border-color: transparent;
	margin-top: 0;
	//padding:30px 0;
}

.nav-tabs li a {
	color: #555;
	border: 0;
	border-bottom: 1px solid;
	border-color: transparent;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid;
	border-color: #555;
}

.nav-tabs > li > a:hover {
	background-color: transparent;
	border: 0;
	color: $color6;
}

.review .date-col img {
	max-width: 100%;
	margin: 10px 0;
}

.review .day, .bloglink .day {
	font-weight: bold;
	font-size: 3em;
	width: 100%;
	text-align: right;
	padding: 0;
	margin: 0;
	line-height: 1.1;
	margin-top: 20px;
}

.review .year-mnth, .bloglink .year-mnth {
	width: 100%;
	text-align: right;
	padding: 0;
	margin: 0;
	line-height: 30px;
	text-transform: uppercase;
	border-bottom: 1px solid;
	border-color: #f1f1f1;
	padding-bottom: 10px;
}

.bloglink .year-mnth {
	border-bottom: 0;
}

.review, .resource, .bloglink {
	border-bottom: 1px solid;
	border-color: #f1f1f1;
	padding-bottom: 40px;
	margin-bottom: 40px;
}

.related {
	@include backgroundimage($file: "default-bg.jpg", $path: $image-header-base, $repeat: no-repeat, $position: center top );
	background-size: cover;
}

.mapquote {
	display: none;
}

.mappoint {
	margin-top: -100px;
	margin-left: -100px;
}

.rellinks a.active {
	color: $color8;

	&:hover {
		text-decoration: none;
	}
}


.specbox {
	min-height: 550px;
}

.specbox.active {
	background-color: $color6;
	color: white;
	position: relative;
	z-index: 5;
}

.specbox.active li {
	color: white !important;
}

.specbox.active .color3 {
	color: white;
}

.specbox.active .btn-red {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
	border: 1px solid #fff;

	&:hover {
		background-color: $color3;
		color: #fff;
		border-color: $color3;
	}
}

@import "variables";
@import "mixins";

.btn {
    transition: all 0.5s ease 0s;
    margin-right: 4px;
}

.btn-group {
    .btn {
        margin-right: 0;

        &:last-child {
            margin-right: 4px;
        }
    }
}

.btn-primary {
    color: white;
    background-color: $color1;
    border-color: darken($color1, 2%);

    &:hover, &:active, &.active {
        color: white;
        background-color: darken($color1, 10%);
        border-color: darken($color1, 12%);
    }

    &:focus {
        color: white;
        background-color: darken($color1, 10%);
        border-color: darken($color1, 25%);
    }
}

.btn-blue {
    color: #fff;
    background-color: #3B4395;
    border-color: #3B4395 !important;
    border: 1px solid #3B4395 !important;

    &:hover {
        background-color: #000;
        color: #fff;
        border-color: #000;
    }
}

/*.btn-blue {
    color: $color1 !important;
    background: transparent;
    border: 1px solid $color1 !important;

    &:hover {
        background: $color1;
        color: #fff !important;
    }
}
*/
.btn-white {
    color: #fff !important;
    background-color: transparent;
    border: 1px solid #fff !important;

    &:hover {
        background-color: #fff;
        color: $color1 !important;
        border-color: $color1;
    }
}

.btn-white:hover {
    color: #999 !important;
}

.btn-black {
    color: #000 !important;
    background-color: transparent;
    border: 1px solid #000 !important;

    &:hover {
        background-color: #000;
        color: #fff !important;
        border-color: #fff;
    }
}

.btn-grey {
    color: #ccc !important;
    background-color: transparent;
    border: 1px solid #ccc !important;

    &:hover {
        background-color: #ccc;
        color: #555 !important;
        border-color: #555;
    }
}

.btn-green {
    color: $color5;
    background-color: transparent;
    border-color: $color5;
    border: 1px solid $color5;

    &:hover {
        background-color: $color5;
        color: #fff;
        border-color: $color5;
    }
}

.btn-red {
    color: $color3;
    background-color: transparent;
    border-color: $color3;
    border: 1px solid $color3;

    &:hover {
        background-color: $color3;
        color: #fff;
        border-color: $color3;
    }
}

.btn-new-blue {
    color: #fff;
    background-color: #3B4395;
    border-color: #3B4395 !important;
    border: 1px solid #3B4395 !important;

    &:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #000 !important;
    }
}

.btn-new-green {
    color: #fff;
    background-color: #7BC5B1;
    border-color: #7BC5B1 !important;
    border: 1px solid #7BC5B1 !important;

    &:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #000 !important;
    }
}

.btn-new-large {
    padding: 10px 20px;
}

@import "variables";
@import "mixins";

.modal-backdrop {
  z-index:1000;
}

.modal-dialog {
  margin-top:15%;
  //width:60%;
  //background-color:white;
}

.modal-content {
  display:inline-block;
  border-radius:0;
  box-shadow: none;
  width:100%;
}

/*.modal-lg .modal-content {
  background-color:transparent;
  border:0;
}
.modal-content {
  display:inline-block;
  border-radius:0;
  box-shadow: none;
  width:100%;
}

#searchModal .modal-content, #enquireModal .modal-content, #s2fModal .modal-content {
  border-radius:0;
  background-color: transparent;
  border:0;
}

#searchModal.modal-content, #enquireModal.modal-content, #s2fModal.modal-content {
    border: 0;
    box-shadow: none;
    margin-top:40vh;
}

#searchModal .modal-dialog, #enquireModal .modal-dialog, #s2fModal .modal-dialog {
  background-color:transparent;
}

#searchModal .form-control, #enquireModal .form-control, #s2fModal .form-control {
  width:80%;
  float:left;
  margin:0;
  border:0;
  border-radius:0;
  padding:38px;
}
#searchModal .input-group-btn, #enquireModal .input-group-btn, #s2fModal .input-group-btn {
  width:10%;
  float:left;
}

#searchModal .input-group-btn .btn, #enquireModal .input-group-btn .btn, #s2fModal .input-group-btn .btn {
  border:0;
  border-radius:0;
  padding:25px;
}*/
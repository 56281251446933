@import "../variables";
@import "../mixins";

.hero-blk {
    min-height: 150px;
    padding-top: 100px;
}

.hero-blk .light a {
    font-weight: bold;
}

.hero-default, .hero-blk1, .hero-blk2, .hero-blk3, .hero-blk4, .hero-blk5, .hero-blk6, .hero-clear {

    .container {
        min-height: 150px;
        background-position-y: -10px;
    }
}


.featured-blk, .feature-blk1, .feature-blk2, .feature-blk3, .feature-blk4, .feature-blk5, .feature-blk6 {
    min-height: 200px;
    background-size: 75% !important;
    background-position: center;
    background-position-y: 0;
}

.product .hero-blk1, .product .hero-blk2, .product .hero-blk3, .product .hero-blk4, .product .hero-blk5, .product .hero-blk6,
.productfilter .hero-blk1, .productfilter .hero-blk2, .productfilter .hero-blk3, .productfilter .hero-blk4, .productfilter .hero-blk5, .productfilter .hero-blk6,
.article .hero-blk1, .article .hero-blk2, .article .hero-blk3, .article .hero-blk4, .article .hero-blk5, .article .hero-blk6 {
    background-color: transparent;
}

.product .hero-blk {
    padding-bottom: 41px;
}

.product-blk {
    min-height: 460px;
}

.product-blk1 {
    @include backgroundimage("workstations-bg2.jpg", $image-header-base, $position: center top);
    background-size: cover;
}

.product-blk2 {
    @include backgroundimage("servers-bg.jpg", $image-header-base, $position: center top);
    background-size: cover;
}

.product-blk3 {
    @include backgroundimage("solutions-bg.jpg", $image-header-base, $position:center top);
    background-size: cover;
}

.product-blk4 {
    @include backgroundimage("storage-bg.jpg", $image-header-base,$position: center top);
    background-size: cover;
}

.product-blk5 {
    @include backgroundimage("networking-bg.jpg", $image-header-base,$position: center top);
    background-size: cover;
}

.product-blk6 {
    @include backgroundimage("components-bg.jpg", $image-header-base,$position: center top);
    background-size: cover;
}

.product-blk7 {
    @include backgroundimage("services.jpg", $image-header-base,$position: center top);
    background-size: cover;
}

.feature-blk {
    @include backgroundimage("icon-features.png", $image-icon-base, $position:center top);
    background-size: cover;
}

.productshot {
    margin-top: -75px;
    height: auto;
}

#overview .productshot {
    margin-top: 0;
    padding-bottom: 20px;
}

.rel-product {
    max-height: 250px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

ul#products {
    list-style-type: none;
}

.halfway {
    margin-top: 20vh;
    max-height: 80vh;
}


.carousel-fade .carousel-inner .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 2;
}

.carousel-indicators {
    bottom: 20px;
}

.carousel,
.carousel-inner,
.carousel-inner .item {
    //height: 68vh;
    //min-height: 500px;
    background-color: black;
    background-size: cover !important;
    background-position: center !important;
}

.carousel-scroll {
    display: none;
    position: absolute;
    background: url("/assets/images/2016/arrow.png") no-repeat scroll center center;
    bottom: 0;
    height: 100px;
    text-indent: -999999px;
    position: absolute;
    z-index: 15;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
    margin-bottom: 10px;
    text-align: center;
    text-align: center;
    list-style: none;
    background-repeat: no-repeat;
    background-size: 20%;
}

.hero .jumbotron h1 {
    font-size: 2em;
}

.hero .jumbotron p {
    font-size: 1.3em;
}

@media (max-width: 1200px) {
    .jumbotron, .jumbotron-nosurround {
        margin-top: 110px !important;
        border-radius: 0;
    }
}

@media (max-width: 768px) {
    .hero .carousel, .carousel-inner, .hero .carousel-inner .item {
        background-color: black;
        background-size: cover;
        background-position: center;
    }

    .hero .carousel-indicators {
        bottom: 0;
    }

    .hero .jumbotron {
        padding: 5px 10px 10px 10px;
    }

    .hero .jumbotron h1, .jumbotron-nosurround h1 {
        font-size: 1.25em !important;
    }

    .hero .jumbotron p, .jumbotron-nosurround p {
        font-size: 14px;
    }

    .hero .jumbotron, .jumbotron-nosurround {
        margin-top: 110px !important;
        border-radius: 0;
    }
}

.hero {
    .jumbotron {
        margin-bottom: 0;
    }

    .hero-image {
        div.lightbg {
            background: rgba(255, 255, 255, 0.8);
        }
    }
}

@import "variables";

@mixin rounded($radius: 8px) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin rounded_bottom($radius) {
    -moz-border-radius-bottomleft: 6px; 
    -moz-border-radius-bottomright: $radius; 
    -webkit-border-bottom-left-radius: 6px; 
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius; 
    border-bottom-right-radius: $radius; 
}

@mixin rounded_left($radius) {
    -moz-border-radius-topleft: 6px; 
    -moz-border-radius-bottomleft: $radius; 
    -webkit-border-top-left-radius: 6px; 
    -webkit-border-bottom-left-radius: $radius;
    border-top-left-radius: $radius; 
    border-bottom-left-radius: $radius; 
}

@mixin boxshadow($style) {
    -webkit-box-shadow: $style;
    -moz-box-shadow: $style;
	-ms-box-shadow: $style;
    -o-box-shadow: $style;
    box-shadow: $style;
}

@mixin backgroundimage($file,$path: "/assets/images/2016/", $repeat: no-repeat,$position:0 0, $attachment: scroll) {
  $url: "#{$path}#{$file}";
  background: url($url) $repeat $attachment $position;
}
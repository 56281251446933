﻿@import "variables";
@import "mixins";

.drop-shadows
{
  position: relative;
}
.drop-shadows:before {
  position: absolute;
  content: "";
  bottom: 10px;
  left: 5%;
  width: 90%;
  top: 80%;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
}
﻿@import "variables";
@import "mixins";

.maps {
  padding:50px 0;
}

.overlay {
   background:transparent; 
   position:absolute; 
   width:100%;
   height:500px; /* your iframe height */
   top:500px;  /* your iframe height */
   margin-top:-500px;  /* your iframe height */
}

.overlay:hover {
  height: 100%;
  background: transparent;
  cursor: pointer;
  
}
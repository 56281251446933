@import "variables";
@import "mixins";

.product-grid-item {
    padding-right: 0;

    & > div.row {
        padding: 15px 0;
    }
}

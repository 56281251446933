﻿@import "variables";
@import "mixins";

.promo-blk {
  min-height: 220px;
}

.promo-blk1 {
    @include backgroundimage($file: "isc.jpg", $path: $image-promo-base, $position: center);
  background-size:cover;
}

.promo-blk2 {
    @include backgroundimage($file: "nvidia.jpg", $path: $image-promo-base, $position: center);
  background-size:cover;
  background-position: center;
}
﻿@import "variables";
@import "mixins";

.contentblock {
  //margin-bottom:50px;
  border-bottom:1px solid $color7;
  padding: 20px 0;
}

.contentblock h3 {
  padding:20px 0;
  }

.quote {
  font-size:2.5em;
  line-height:1.25em;
  font-style:italic;
  //text-align: right;
  margin:20px 0;
}


.worldmap {
  padding:10% 0;
  @include backgroundimage($file: "worldmap.png", $repeat: no-repeat, $position: center);
  background-size:75%;
}


.headshot {
  margin-bottom:30px;
  position: relative;
  vertical-align: top;
}

.headshot-mini-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .5s;
  overflow: hidden;
  height: 100px;
  margin: 0 auto;
}

.headshot-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .5s;
  overflow: hidden;
  height: 0;
  margin: 0 auto;
  padding:0;
  margin:0 15px;
}

.headshot:hover .headshot-overlay,
.overlay:hover {
  height: 100%;
  background: rgba(0, 173, 216, 0.5);
  
}
.headshot > img {
  display: block;/* Prevent inline gap under image*/
}

.headshot-overlay p {
  font-weight:400;
  font-size:1.5em;
  //padding-top:50%;
  //position:relative;
  width:100%;
  text-align:center;
  position:absolute;
  bottom:0;
  left:0;
}

.headshot-title {
  font-weight:300;
  font-size:0.8em;
}

.gallery-image {
  height:100px;
}
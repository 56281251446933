@import "../variables";
@import "../mixins";

.nl-sml-blk {
	padding: 20px 0;

	.newsletter-label {
		line-height: 34px;
	}
}

.newsletterModule.nl-sml-blk {
	padding: 10px 0;

	.newsletter-label {
		line-height: 34px;
	}
}

@media (max-width: 767px) {
	.nl-sml-blk {
		.newsletter-label {
			text-align: center;

			label {
				line-height: 34px;
				margin-bottom: 0;
			}
		}

		.newsletter-form {
			text-align: center;
		}

		.form-control {
			display: inline;
		}
	}
}

$image_base: "/assets/images/2016/" !default;
$image-icon-base: "/assets/images/2016/icons/";
$image-header-base: "/assets/images/2016/headerbar/";
$image-promo-base: "/assets/images/2016/promobar/";

$color1: #4ec2eb;
$color2: #fecf44;
$color3: #e03416;
$color4: #3b4395;
$color5:#13b83a;
$color6: #666362;
$color7: #ededed;
$color8: #141414;
$color-white: #ffffff;
$new-color1: #3b4395;
$new-color2: #4ec2eb;
$new-color3: #539c74;
$new-color4: #7bc5b1;
$new-color5: #35445b;
$new-color6: #000000;
$new-color7: #666362;
$new-color8: #ededed;
$new-color9: #fecf44b;
$new-color10: #e03416;
$new-color11: #e72864;
$new-color12: #6f2a59;

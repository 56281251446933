@import "variables";
@import "mixins";

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  overflow-x:hidden;
  color:$color8;
}

a {
  font-weight:400;
}

h1, h2, h3, h4, h5 {
    font-family: Qanelas-Regular, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
}

h1 {
  font-size:3em;
}

h3 {
  font-size:1.5em;
}

p {
  margin:0 0 20px;
}
 label {
  text-transform:uppercase;
  font-weight:400;
  margin-right:20px;
 }

#nl-email {
  width:300px;
}
.input-group .form-control:first-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
